var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form-item',{attrs:{"label":"内部API服务器域名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.api_server_domain', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaUnicomApiAccountForm.api_server_domain,
        rules: [{ required: true, message: '请输入内部API服务器域名' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_unicom_api_account_form.api_server_domain', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaUnicomApiAccountForm.api_server_domain,\n        rules: [{ required: true, message: '请输入内部API服务器域名' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{attrs:{"label":"接口类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.account_type', {
        initialValue: _vm.chinaUnicomApiAccountForm.account_type,
        rules: [{ required: true, message: '请选择接口类型' }
        ]
      }]),expression:"['china_unicom_api_account_form.account_type', {\n        initialValue: chinaUnicomApiAccountForm.account_type,\n        rules: [{ required: true, message: '请选择接口类型' }\n        ]\n      }]"}],on:{"change":_vm.selectAccountType}},[_c('a-select-option',{key:"CMP",attrs:{"value":"CMP"}},[_vm._v(" CMP ")]),_c('a-select-option',{key:"COP",attrs:{"value":"COP"}},[_vm._v(" COP ")])],1)],1),_c('a-form-item',{attrs:{"label":"API访问路径"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.api_url', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaUnicomApiAccountForm.api_url,
        rules: [{ required: true, message: '请输入API访问路径' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_unicom_api_account_form.api_url', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaUnicomApiAccountForm.api_url,\n        rules: [{ required: true, message: '请输入API访问路径' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCMPParams || _vm.isShowCOPParams),expression:"isShowCMPParams || isShowCOPParams"}],attrs:{"label":"APP ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.app_id', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaUnicomApiAccountForm.app_id,
        rules: [{ required: _vm.isShowCMPParams, message: '请输入APP ID' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_unicom_api_account_form.app_id', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaUnicomApiAccountForm.app_id,\n        rules: [{ required: isShowCMPParams, message: '请输入APP ID' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCMPParams || _vm.isShowCOPParams),expression:"isShowCMPParams || isShowCOPParams"}],attrs:{"label":"APP SECRET"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.app_secret', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaUnicomApiAccountForm.app_secret,
        rules: [{ required: _vm.isShowCMPParams, message: '请输入APP SECRET' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_unicom_api_account_form.app_secret', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaUnicomApiAccountForm.app_secret,\n        rules: [{ required: isShowCMPParams, message: '请输入APP SECRET' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCMPParams),expression:"isShowCMPParams"}],attrs:{"label":"OPEN ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.open_id', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaUnicomApiAccountForm.open_id,
        rules: [{ required: _vm.isShowCMPParams, message: '请输入OPEN ID' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_unicom_api_account_form.open_id', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaUnicomApiAccountForm.open_id,\n        rules: [{ required: isShowCMPParams, message: '请输入OPEN ID' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCOPParams),expression:"isShowCOPParams"}],attrs:{"label":"Api Username"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.api_username', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaUnicomApiAccountForm.api_username,
        rules: [{ required: _vm.isShowCOPParams, message: '请输入Api Username' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_unicom_api_account_form.api_username', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaUnicomApiAccountForm.api_username,\n        rules: [{ required: isShowCOPParams, message: '请输入Api Username' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCOPParams),expression:"isShowCOPParams"}],attrs:{"label":"Api Password"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.api_password', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaUnicomApiAccountForm.api_password,
        rules: [{ required: _vm.isShowCOPParams, message: '请输入Api Password' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_unicom_api_account_form.api_password', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaUnicomApiAccountForm.api_password,\n        rules: [{ required: isShowCOPParams, message: '请输入Api Password' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCOPParams),expression:"isShowCOPParams"}],attrs:{"label":"Tenant ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.tenant_id', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaUnicomApiAccountForm.tenant_id,
        rules: [{ required: _vm.isShowCOPParams, message: '请输入Tenant ID' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_unicom_api_account_form.tenant_id', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaUnicomApiAccountForm.tenant_id,\n        rules: [{ required: isShowCOPParams, message: '请输入Tenant ID' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCOPParams),expression:"isShowCOPParams"}],attrs:{"label":"Encode Key"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.encode_key', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaUnicomApiAccountForm.encode_key,
        rules: [{ required: _vm.isShowCOPParams, message: '请输入Encode Key' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_unicom_api_account_form.encode_key', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaUnicomApiAccountForm.encode_key,\n        rules: [{ required: isShowCOPParams, message: '请输入Encode Key' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCOPParams),expression:"isShowCOPParams"}],attrs:{"label":"Iv Key"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.iv_key', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaUnicomApiAccountForm.iv_key,
        rules: [{ required: _vm.isShowCOPParams, message: '请输入Iv Key' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_unicom_api_account_form.iv_key', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaUnicomApiAccountForm.iv_key,\n        rules: [{ required: isShowCOPParams, message: '请输入Iv Key' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCOPParams),expression:"isShowCOPParams"}],attrs:{"label":"Push Key"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.push_key', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaUnicomApiAccountForm.push_key,
        rules: [{ required: _vm.isShowCOPParams, message: '请输入Push Key' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_unicom_api_account_form.push_key', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaUnicomApiAccountForm.push_key,\n        rules: [{ required: isShowCOPParams, message: '请输入Push Key' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{attrs:{"label":"调用限频"}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',{style:({ display: 'inline-block', width: '55%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.rate_count', {
              initialValue: _vm.chinaUnicomApiAccountForm.rate_count == 0 ? undefined : _vm.chinaUnicomApiAccountForm.rate_count,
              rules: [{ type: 'integer', message: '请输入 1 - 10000000 之间的整数' }]
            }]),expression:"['china_unicom_api_account_form.rate_count', {\n              initialValue: chinaUnicomApiAccountForm.rate_count == 0 ? undefined : chinaUnicomApiAccountForm.rate_count,\n              rules: [{ type: 'integer', message: '请输入 1 - 10000000 之间的整数' }]\n            }]"}],staticStyle:{"width":"135px"},attrs:{"min":1,"max":10000000}}),_c('span',[_vm._v("次")])],1)],1),_c('a-form-item',{style:({ display: 'inline-block', width: '40%', marginBottom: 0 })},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_unicom_api_account_form.rate_time_unit', {
            initialValue: _vm.chinaUnicomApiAccountForm.rate_time_unit
          }]),expression:"['china_unicom_api_account_form.rate_time_unit', {\n            initialValue: chinaUnicomApiAccountForm.rate_time_unit\n          }]"}],staticStyle:{"width":"140px"}},[_c('a-select-option',{attrs:{"value":"second"}},[_vm._v(" 每秒 ")]),_c('a-select-option',{attrs:{"value":"minute"}},[_vm._v(" 每分 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }